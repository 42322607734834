.Carousel-override {
    background: rgb(240,240,240);
}

.Carousel_item-text-override {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: white;
    opacity: 0.8;
    color: black;
}

.Carousel_item-text-override > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: .8rem 1rem;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
  }

.Carousel-img-override {
    width: 100%;
    height: 100%;
}

.Carousel_item-override {
    width: 95%;
    height: 160px;
    position: relative;
}

.Carousel_imgp-override {
    height: 100%;
    width: 100%;
    filter: brightness(50%);
  }
  
.Carousel_play-button-override{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #191919;
    color: whitesmoke;
    font-size: 20px;
    border: solid whitesmoke;
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    padding-left: 6px;
}

.Profile_carousel .slick-prev {
    background-color: #e5e5e5 !important;
}

.Profile_carousel .slick-next {
    background-color: #e5e5e5 !important;
}