.Spotify_container {
    color: white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.Spotify_title {
    font-size: 4rem;
    margin-bottom: 2rem;
}

.Spotify_greeting {
    margin-top: 2rem;
    font-size: 3rem;
}

.Spotify_description {
    text-align: center;
    /* width: 30rem; */
    margin: 2rem;
    line-height: 2;
}

.Spotify_button {
    display: inline-block;
    border-radius: 4px;
    background-color: green;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 2rem;
    padding: .5rem 2rem .5rem 2rem;
    transition: all 0.5s;
    cursor: pointer;
    margin: .5rem;
}

.Spotify_button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

.Spotify_button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -2rem;
    transition: 0.5s;
}
  
.Spotify_button:hover span {
    padding-right: 2.5rem;
}
  
.Spotify_button:hover span:after {
    opacity: 1;
    right: 0;
}