@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

/* * {
	box-sizing: border-box;
} */

.LoginSignup_container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 640px;
	margin: auto;
}

.LoginSignup_form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.LoginSignup_form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.LoginSignup_input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 6px 0;
	width: 100%;
}

.LoginSignup_signup-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.LoginSignup_signup-p {
	margin-bottom: 0;
	text-align: center;
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
}

.LoginSignup_login-p {
	text-align: center;
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.LoginSignup_birthday-container {
	background-color: #eee;
	margin: 6px 0;
	width: 284px;
	align-items: flex-start;
	/* height:; */
}

.LoginSignup_birthday-label {
	text-align: left;
	padding-right: 200px;
	color: darkGray;
	font-size: 0.83em;
	}

.LoginSignup_birthday-input {
	margin: 0;
}

.LoginSignup_gender-container {
	background-color: #eee;
	margin: 6px 0;
	width: 100%;
}

#LoginSignup_gender {
	background-color: #eee;
	margin: 0 0 36px 0;
}

.LoginSignup_gender-label {
	padding-right: 205px;
	color: darkGray;
	font-size: 0.83em;
}

.LoginSignup_gender-input {
	width: 27px;
	margin: 15px 0 15px 30px;
}

.LoginSignup_login-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.LoginSignup_container.right-panel-active .LoginSignup_login-container {
	transform: translateX(100%);
}

.LoginSignup_container.right-panel-active .LoginSignup_signup-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.LoginSignup_overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.LoginSignup_container.right-panel-active .LoginSignup_overlay-container{
	transform: translateX(-100%);
}

.LoginSignup_overlay {
	background: linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43));
	background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.LoginSignup_container.right-panel-active .LoginSignup_overlay {
  	transform: translateX(50%);
}

.LoginSignup_overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.LoginSignup_overlay-left {
	transform: translateX(-20%);
}

.LoginSignup_container.right-panel-active .LoginSignup_overlay-left {
	transform: translateX(0);
}

.LoginSignup_overlay-right {
	right: 0;
	transform: translateX(0);
}

.LoginSignup_container.right-panel-active .LoginSignup_overlay-right {
	transform: translateX(20%);
}

.LoginSignup_social-container {
	margin: 20px 0;
}

.LoginSignup_social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.LoginSignup_validation-email {
	color: red;
	text-align: left;
	font-size: 1rem;
	margin-right: 179px;
	margin-bottom: 10px;
}

.LoginSignup_validation-username {
	color: red;
	text-align: left;
	font-size: 1rem;
	margin-right: 154px;
	margin-bottom: 10px;
}

/* h1 {
	font-weight: bold;
	margin: 10px 0;
	font-size: 2.4rem;
}

h2 {
	text-align: center;
} */

.LoginSignup_feature-p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px 11px;
	text-align: left;
}

/* span {
	font-size: 12px;
}

a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
} */

.LoginSignup_button {
	border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	margin: 15px 0 25px 0;
}

.LoginSignup_button:active {
	transform: scale(0.95);
}

.LoginSignup_button:focus {
	outline: none;
}

.LoginSignup_button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

/* footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
} */