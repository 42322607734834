.SidebarOverlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}

@media(min-width: 600px) {
  .SidebarOverlay {
    display: none !important;
  }
}

.SidebarOverlay_sidebar {
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  text-align: center;
  width: 200px;
  height: 100%;
}

.SidebarOverlay_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0,0,0,.2);
  border-top: 1px solid hsla(0,0%,100%,.05);
  font-family: Circular,Helvetica,Arial,sans-serif,iosfallbackfix;
  color: #919496;
  font-size: 2rem;
  padding: 2rem 5rem;
  text-decoration: none;
}

.SidebarOverlay_item:hover {
  background-color: #1c1d1e;
}

.SidebarOverlay_item-name {
  font-size: 1.6rem;
}

.SidebarOverlay_close {
  cursor: pointer;
  margin: 2rem 5rem;
  font-size: 2rem;
}