.Carousel {
  padding: 1rem 3rem;
  background: #191919;
  width: 100%;
}

.Carousel_title {
  color: white;
  margin: 0 1rem 1rem 1rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 5px;
}

@media(max-width: 480px) {
  .Carousel_title {
    font-size: 1.8rem;
  }
}

.Carousel_loading {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.Carousel_loading-icon {
  font-size: 3rem !important;
  margin: 1.2rem !important;
}

.Carousel_loading-text {
  color: white;
  margin: 1.3rem;
}

.Carousel_fail {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.Carousel_fail-icon {
  color: orange;
  font-size: 3rem;
  margin: 1rem;
}

.Carousel_fail-text {
  color: white;
  margin: 1rem;
}

@media(max-width: 600px) {
  .Carousel_fail-text {
    font-size: 1.2rem;
  }
}

@media(max-width: 480px) {
  .Carousel_fail-text {
    font-size: 1rem;
  }
}

.Carousel_item {
  position: relative;
}

.Carousel_pic {
  width: 95%;
  height: 200px;
}

.Carousel_img {
  height: 100%;
  width: 100%;
}

.Carousel_imgp {
  height: 100%;
  width: 100%;
  filter: brightness(50%);
}

.Carousel_play-button{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #191919;
  color: whitesmoke;
  font-size: 27px;
  border: solid whitesmoke;
  cursor: pointer;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  padding-left: 12px;
}

.Carousel_artist-iframe{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.Carousel_item-text {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #191919;
  opacity: 0.8;
  color: white;
}

.Carousel_item-text > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: .8rem 1rem;
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
}