.SpotifyResult {
    height: 100%;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    vertical-align: center;
    align-items: center;
    align-content: center;
    text-align: center;
    background-color: #f0f4f7;
    font-family: Whitney,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.SpotifyResult-icon-success {
    font-size: 20rem;
    color: #1DB954;
}

.SpotifyResult-icon-failed {
    font-size: 20rem;
    color: #191414;
}

.SpotifyResult-text-success {
    font-size: 1.8rem;
}

.SpotifyResult-text-failed {
    font-size: 1.8rem;
    color: red;
}