.Main_row {
	display: flex;
	margin: 0 15rem;
}

@media (max-width: 1200px) {
  .Main_row {
    margin: 0 9rem;
  }
}

@media(max-width: 992px) {
	.Main_row {
		margin: 0;
	}
}

@media(max-width: 768px) {
	.Main_row {
		margin: 0;
	}
}

@media(max-width: 600px) {
	.Main_row {
		margin: 0;
	}
}

.Main-sidebar {
	height: 100%;
}

.Main_content-container {
	flex-grow: 1;
	width: 0%;
	background-color: rgb(248, 248, 248);
}

@media(max-width: 600px) {
	.Main_content-container {
		margin-top: 70px;
	}
}
