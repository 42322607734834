.Interest {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
}

.Interest > h1 {
    color: #f6f7d7 !important;
    text-align: center;
}

.Interest > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #f6f6f6;
    width: 60%;
    height: 42rem; 
    border-radius: 2rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    box-shadow: 0 14px 28px rgba(238, 222, 222, 0.25), 
			0 10px 10px rgba(252, 240, 240, 0.24);
}

@media(max-width: 1200px) {
    .Interest > div {
        width: 70%;
    }
}

@media(max-width: 992px) {
    .Interest > div {
        width: 80%;
    }
}

@media(max-width: 768px) {
    .Interest > div {
        width: 90%;
    }
}

.Interest > div > div {
    width: 13rem;
    height: 13rem;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 4.5rem;
    text-align: center;
}

.Interest > div > div > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    cursor: pointer;
}

.Interest > div > div > label {
    margin-top: 0;
    font-size: smaller;
}

.Interest > div > div .selected {
    background-color: #f79071 !important;
    color: white !important;
}