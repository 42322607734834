.Match_Filter-container {
    border-bottom: 2px solid #c5cbc8;
    display: flex;
    flex-direction: row;
    padding: 2rem 4rem;
}

@media screen and (max-width: 768px) {
    .Match_Filter-container {
        flex-direction: column;
    }
}

.Match_Filter-gender {
    margin: 0 1rem;
    flex: 10%;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 768px) {
    .Match_Filter-gender {
        justify-content: center;
        margin: 0 1rem 1rem 1rem;
    }
}

.Match_Filter-gender-icon {
    font-size: 5rem;
}

@media screen and (max-width: 768px) {
    .Match_Filter-gender-icon {
        margin: 0 2rem;
    }
}

.Match_Filter-age {
    flex: 40%;
    align-items: center;
    margin: 0 3rem 0 0;
}

@media screen and (max-width: 768px) {
    .Match_Filter-age {
        justify-content: center;
        margin: 2rem 1rem;
    }
}

.Match_Filter-distance {
    flex: 50%;
    align-items: center;
    margin: 0 0 0 3rem;
}

@media screen and (max-width: 768px) {
    .Match_Filter-distance {
        justify-content: center;
        margin: 2rem 1rem;
    }
}

.Match_Filter-slider {
    display: block;
}

.Match_Filter-slider-title {
    margin-right: 1.5rem;
}

.pending {
    color: white;
    background-color: grey;
    margin-top: 30px;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 3px 0px 0px grey;
    letter-spacing: 0.07em;
    transition: all 0.4s ease;
}

@media screen and (max-width: 768px) {
    .pending {
        margin: 1.5rem 0 0 2rem;
    }
}

@media screen and (max-width: 600px) {
    .pending {
        width: 50%;
        margin: 10px auto;
        padding: 10px;
    }
}

.pending:hover {
    transform: translateY(-10px);
    box-shadow: 0px 40px 29px -19px grey;
}

@media screen and (max-width: 768px) {
    .pending:hover {
        transform: none;
        box-shadow: none;
    }
}

.pending:active {
    box-shadow: inset 0 0 10px 1px grey,
      0px 40px 29px -19px grey;
    transform: scale(0.95) translateY(-9px);
}

@media screen and (max-width: 768px) {
    .pending:active {
        transform: scale(0.95) translateY(0);
        box-shadow: none;
    }
}